import React from 'react'
import styles from './Content.module.css'
import Welcome from './Welcome'
import Project from './Project'
import { motion } from 'framer-motion'
import { Route, Routes, useNavigate } from 'react-router-dom'

const Content = () => {
  const navigate = useNavigate()

  const handleClick = (path) => {
    if (window.location.pathname === path) {
      navigate('/')
    } else {
      navigate(path)
    }
  }

  return (
    <>
      <div className={styles.mainConatiner}>
        <motion.div
          layout
          transition={{
            opacity: { ease: 'linear' },
            layout: { duration: 0.3 },
          }}
          className={styles.leftConatainer}
        >
          <Routes>
            <Route exact path='/' element={<Welcome />} />
            <Route path='/something' element={<Project />} />
            <Route path='/somewhat' element={<Project />} />
            <Route path='/somewhere' element={<Project />} />
            <Route path='/everything' element={<Project />} />
          </Routes>
        </motion.div>
        <div className={styles.rightContainer}>
          <div className={styles.project}>Projects</div>
          <div className={styles.wrapper}>
            <div className={styles.projectContainer}>
              <div className={styles.upper}></div>
              <div className={styles.projects}>
                <ul>
                  <div className={styles.projectName}>
                    <li onClick={() => handleClick('/something')}>Something</li>
                    <div className={styles.line}></div>
                  </div>
                  <div className={styles.projectName}>
                    <li onClick={() => handleClick('/somewhat')}>Somewhat</li>
                    <div className={styles.line}></div>
                  </div>
                  <div className={styles.projectName}>
                    <li onClick={() => handleClick('/somewhere')}>Somewhere</li>
                    <div className={styles.line}></div>
                  </div>
                  <div className={styles.projectName}>
                    <li onClick={() => handleClick('/everything')}>
                      Everything
                    </li>
                    <div className={styles.line}></div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content

import React, { useState } from 'react'
import styles from './Layout.module.css'
import Main from '../Main/Main'

const Layout = () => {
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleHoverEnd = () => {
    setIsHovered(false)
  }

  return (
    <div className={styles.mainContainer}>
      <div
        className={`${styles.container} ${isHovered ? styles.hovered : ''}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverEnd}
      >
        <div className={styles.upper}></div>
        <div className={styles.lower}></div>
        <div className={styles.main}>
          <Main />
        </div>
      </div>
    </div>
  )
}

export default Layout

import React from 'react'
import { motion } from 'framer-motion'
import styles from './Content.module.css'

const Welcome = () => {
  return (
    <>
      <motion.div
        className={styles.title}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
      >
        Welcome.
      </motion.div>
      <div className={styles.contentContainer}>
        <div className={styles.side}></div>
        <div className={styles.content}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, placeat
          autem? Et nemo rerum ex, praesentium consectetur iure reiciendis sed
          eligendi sapiente repellendus suscipit aliquam, aliquid molestias
          distinctio alias cumque? <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi debitis
          obcaecati sequi blanditiis animi officia voluptatibus facilis fugiat
          ipsa quam?
        </div>
      </div>
    </>
  )
}

export default Welcome

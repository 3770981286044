import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import styles from './Main.module.css'
import Footer from '../Footer/Footer'
import Content from '../Content/Content'

const Main = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.nav}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <BrowserRouter>
          <Content />
        </BrowserRouter>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  )
}

export default Main

import React from 'react'
import styles from './Content.module.css'

const Project = () => {
  return (
    <>
      <div className={styles.projecttitle}>Project.</div>
      <div className={styles.contentContainer}>
        <div className={styles.side}></div>
        <div className={styles.content}>
          <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
            placeat autem? Et nemo rerum ex, praesentium consectetur iure
            reiciendis sed eligendi sapiente repellendus suscipit aliquam,
            aliquid molestias distinctio alias cumque? <br />
            <br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
            debitis obcaecati sequi blanditiis animi officia voluptatibus
            facilis fugiat ipsa quam?
          </div>
          <div className={styles.links}>
            <ul>
              <div className={styles.open}>
                <a>View Site</a>
                <div className={styles.linkline}></div>
              </div>
              <div className={styles.open}>
                <a>Github</a>
                <div className={styles.linkline}></div>
              </div>
              <div className={styles.open}>
                <a>Npm</a>
                <div className={styles.linkline}></div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Project

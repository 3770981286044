import './App.css'
import Layout from './Components/Layout/Layout'
// Problem in the .projectConatiner of the Content.jsx
function App() {
  return (
    <>
      <Layout />
    </>
  )
}

export default App
